import React from "react";
import {ConstInfo} from "../CostInfo";
import {NumberHeader} from "../NumberHeader";
import { Gallery } from "../Gallery";

export const Home = React.memo(()=> {
    return(
        <div
            style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
        }}>
            <NumberHeader />
            <ConstInfo />
            <Gallery />
        </div>
    )
})